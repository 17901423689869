<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>教材邮寄管理</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item  v-if="this.privRange.havePriv()">
                                    <el-select v-model="queryParams.companyId" placeholder="请选择培训机构"  @change="getClassList" style="width:150px">
                                        <el-option value="">全部</el-option>
                                        <el-option v-for="item in orgList" :key="item.orgId" :value="item.orgId" :label="item.orgName"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.classId" placeholder="请选择班级" clearable style="width:100px">
                                        <el-option value="" label="全部"></el-option>
                                        <el-option v-for="item in classList" :key="item.classId" :value="item.classId" :label="item.yearNum + '--' + item.classNum"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.postStatus" style="width:100px">
                                        <el-option value="">全部</el-option>
                                        <el-option value="0" label="未邮寄"></el-option>
                                        <el-option value="1" label="已邮寄"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            <el-button-group style="float: right">
                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExport">导出</el-button>
                            </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                height="calc(100vh - 300px)"
                                style="width: 100%;"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="联系人" prop="linkName" min-width="120px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="电话" prop="linkMobile" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="邮寄地址" min-width="200px">
                                    <template slot-scope="{ row }">
                                        <span>{{row.provinceName + row.cityName + row.districtName + row.addressDesc}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="邮寄状态" prop="postStatus" min-width="120px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.postStatus | colorFilter">
                                            {{ row.postStatus  | postStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="快递名称" prop="expressName" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="快递单号" prop="expressNo" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="班级" prop="className" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名机构" prop="companyName" min-width="120px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="邮寄时间" prop="modifyTime" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="学员确认" prop="receiveStatus" min-width="120px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.receiveStatus | colorFilter">
                                            {{ row.receiveStatus  | receiveStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toPost(row.bookId, row.expressName, row.expressNo)">确认邮寄</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="邮寄信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="快递名称" prop="expressName">
                            <el-input v-model="info.expressName" placeholder="请输入快递名称"></el-input>
                        </el-form-item>
                        <el-form-item label="快递单号" prop="expressNo">
                            <el-input v-model="info.expressNo" placeholder="请输入快递单号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="savePost('editForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
        },
        filters: {
            postStatusFilter(status) {
                const statusMap = {
                    0: '未邮寄',
                    1: '已邮寄',
                };
                return statusMap[status]
            },
            receiveStatusFilter(status) {
                const statusMap = {
                    0: '未确认',
                    1: '已确认'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '教材邮寄管理'
                    }
                ],
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    postStatus: '0',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    userId: this.appCookies.getCookieUID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                orgList: [],
                classList: [],
                //编辑、新增表单
                editDialog: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    expressName: [
                        { required: true, message: '请输入快递名称', trigger: 'blur' }
                    ],
                    expressNo: [
                        { required: true, message: '请输入快递单号', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('user/book/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                    operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toQuery: function (data) {
                this.cellIndex = '-1';
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.refreshTable()
            },
            //导出
            toExport: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'user/book/export',
                    params: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/vnd.ms-excel'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
            toPost: async function (bookId, expressName, expressNo) {
                this.info = {
                    expressName: '',
                    expressNo: ''
                };
                this.info.bookId = bookId;
                if (expressName) {
                    this.info.expressName = expressName;
                    this.info.expressNo = expressNo;
                }
                this.editDialog = true;
            },
            savePost: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "user/book/updPostStatus";
                        this.info.postStatus = 1;
                        this.info.operUserId = this.appCookies.getCookieUID();
                        this.info.operUserName = this.appCookies.getCookieUNAME();
                        // 保存数据
                        this.apis.postForm(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDialog = false;
                                    this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                    this.initTable();
                                } else {
                                    this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
