<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>课程订单查询</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-date-picker
                                        v-model="queryParams.startDate"
                                        type="date"
                                        style="width: 140px"
                                        placeholder="选择日期"
                                        @change="queryParams.startDate=$event">
                                    </el-date-picker>
                                    <span style="padding: 0 10px">至</span>
                                    <el-date-picker
                                        v-model="queryParams.endDate"
                                        type="date"
                                        style="width: 140px"
                                        placeholder="选择日期"
                                        @change="queryParams.endDate=$event">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.payStatus" placeholder="支付状态" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="已支付" value="1"></el-option>
                                        <el-option label="未支付" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.payWay" placeholder="请选择支付方式" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="线下支付" value="3"></el-option>
                                        <el-option label="微信支付" value="1"></el-option>
                                        <el-option label="支付宝支付" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item >
                                    <el-select v-model="queryParams.deleteStatus" placeholder="取消状态" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已取消" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>

                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                :show-header="false"
                                style="width: 100%;"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column>
                                    <template slot-scope="{row}">
                                        <div class="row-item">
                                            <div class="img">
                                                <img :src="row.courseInfo.courseImgUrl" />
                                            </div>
                                            <div class="item-info">
                                                <div class="item">
                                                    <span class="value strong">{{row.courseInfo.courseName}}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">订单号：</span>
                                                    <span class="value">{{row.orderCode}}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">下单时间：</span>
                                                    <span class="value">{{row.modifyTime}}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">总金额：</span>
                                                    <span class="value">{{row.priceValue}}元</span>
                                                </div>
                                            </div>
                                            <div class="item-info">
                                                <div class="item">
                                                    <span class="label">购买机构：</span>
                                                    <span class="value">{{row.orgName}}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">购买人次：</span>
                                                    <span class="value">{{row.courseTimes}}人次</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">单次金额：</span>
                                                    <span class="value">{{row.timesAmount}}元</span>
                                                </div>
                                            </div>
                                            <div class="item-info">
                                                <div class="item">
                                                    <span class="label">支付方式：</span>
                                                    <span class="value">{{row.payWay | payWayFilter}}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">支付金额：</span>
                                                    <span class="value" v-show="row.payValue">{{row.payValue}}元</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">支付时间：</span>
                                                    <span class="value">{{row.payDate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="120">
                                    <template slot-scope="{row}">
                                        <div v-if="row.deleteStatus===1">
                                            <el-tag type="danger">已取消</el-tag>
                                        </div>
                                        <div v-else>
                                            <el-tag v-if="row.payStatus===1" type="success" >已支付</el-tag>
                                            <el-tag v-else type="info">未支付</el-tag>
                                            <el-button  v-if="row.payStatus===0" type="text"  @click="confirmPay(row.orderId)">确认已收款</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                    <!-- 分页组件 -->
                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                @pagination="initTable"/>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    export default {
        components: {
            Pagination,
            Breadcrumb,
        },
        filters: {
            payStatusFilter(status) {
                const statusMap = {
                    1: '已支付',
                    0: '未支付',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    1: '微信支付',
                    2: '支付宝',
                    3: '线下支付'
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '课程订单'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    startDate: '',
                    endDate: '',
                    payWay: '',
                    payStatus: '',
                    deleteStatus: '',
                    fromOrgId: this.appCookies.getCookieOID()
                },
                //视频列表数据
                tableData: [],
                tableLoading: false,
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('/org/order/course/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            confirmPay: function (orderId) {
                this.$confirm('确认已收款？', "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orderId: orderId,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('/org/order/course/updateStatus', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable()
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .row-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        .img {
            height: 100px;
            width: 140px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }
        }
        .item-info {
            height: 100%;
            flex: 1;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                .label {
                    min-width: 80px;
                    text-align: right;
                }
                .value {
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .strong {
                    font-weight: bold;
                }
            }
        }
    }
</style>
