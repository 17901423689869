<template>
    <el-row :gutter="32" >
        <el-col :span="24">
            <el-breadcrumb class="app-breadcrumb" separator="/">
                <el-breadcrumb-item v-for=" (item, idx) in crumbData" :to="{ path: item.path }" :key="idx">{{item.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        props: ['crumbData'],
        data() {
            return {
                levelList: null
            }
        },
        watch: {
        },
    }
</script>

<style lang="scss" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 40px;
        margin-left: 2px;
        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
</style>
