import { render, staticRenderFns } from "./CourseBuyDrawer.vue?vue&type=template&id=696f3f33&scoped=true&"
import script from "./CourseBuyDrawer.vue?vue&type=script&lang=js&"
export * from "./CourseBuyDrawer.vue?vue&type=script&lang=js&"
import style0 from "./CourseBuyDrawer.vue?vue&type=style&index=0&id=696f3f33&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696f3f33",
  null
  
)

export default component.exports