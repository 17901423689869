<template>
    <div class="mainselect">
        <el-row>
            <el-col :span="8" style="padding-right: 4px">
                <el-form-item prop="gnvqCatId">
                    <el-select filterable v-model="n_gnvqCatId" placeholder="请选择工种分类" @visible-change="handleChangeFlag" style="width: 100%">
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in gnvqCatList"
                            :key="item.categoryId"
                            :label="item.categoryName"
                            :value="item.categoryId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8" style="padding-right: 4px">
                <el-form-item prop="gnvqId">
                    <el-select filterable v-model="n_gnvqId" placeholder="请选择所属工种" @visible-change="handleChangeFlag" style="width: 100%;">
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in gnvqList"
                            :key="item.gnvqId"
                            :label="item.gnvqName"
                            :value="item.gnvqId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item prop="levelId">
                    <el-select filterable v-model="n_levelId" placeholder="请选择工种等级" @visible-change="handleChangeFlag" style="width: 100%;" >
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in levelList"
                            :key="item.levelId"
                            :label="item.levelName"
                            :value="item.levelId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        props: ['gnvqCatId', 'gnvqId', 'levelId'],
        // props: {
        //     gnvqCatId: {
        //         type: Number,
        //     },
        //     gnvqId: {
        //         type: Number,
        //     },
        //     levelId: {
        //         type: Number,
        //     },
        // },
        data() {
            return {
                n_gnvqCatId: '',
                n_gnvqId: '',
                n_levelId: '',
                gnvqCatList: [],
                gnvqList: [],
                levelList: [],
                changeFlag: false
            }
        },
        watch: {
            gnvqCatId: {
                handler (val) {
                    console.log("gnvqCatId=" + val)
                    this.n_gnvqCatId = val
                    if (val) {
                        this.getGnvqList(val)
                    }
                },
                deep: true,
                immediate: true
            },
            gnvqId: {
                handler (val) {
                    // console.log("gnvqId=" + val)
                    this.n_gnvqId = val
                    if (val) {
                        this.getLevelList(val)
                    }
                },
                deep: true,
                immediate: true
            },
            levelId: {
                handler (val) {
                    // console.log("levelId=" + val)
                    this.n_levelId = val;
                },
                deep: true,
                immediate: true
            },
            /**
             * 监听省code
             * @param val
             */
            n_gnvqCatId(val) {
                if (this.changeFlag){
                    this.n_gnvqId = null;
                    // this.getGnvqList(val)
                    this.$emit('update:gnvqCatId', val)
                }
            },

            /**
             * 监听市code
             * @param val
             */
            n_gnvqId(val) {
                if (this.changeFlag){
                    this.n_levelId = null;
                    // this.getGnvqList(val)
                    this.$emit('update:gnvqId', val)
                }
            },
            /**
             * 监听区code
             * @param val
             */
            n_levelId(val) {
                if (this.changeFlag){
                    this.$emit('update:levelId', val)
                    this.setData(val)
                }
            },
        },
        mounted() {
            this.getGnvqCatList();
            this.$nextTick(function () {

            });
        },
        methods: {
            initData: function (gnvqCatId, gnvqId, levelId) {
                this.n_gnvqId = gnvqId;
                this.n_levelId = levelId;
                if (this.gnvqCatId) {

                    this.apis.get('gnvq/info/getList?categoryId=' + gnvqCatId + '&orgId=' + this.appCookies.getCookieTOID())
                        .then((res) => {
                            if (res.code === 1) {
                                this.gnvqList = res.data;
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
                if (this.n_gnvqId) {
                    this.apis.get('gnvq/level/getList?gnvqId=' + this.n_gnvqId + '&orgId=' + this.appCookies.getCookieTOID())
                        .then((res) => {
                            if (res.code === 1) {
                                this.levelList = res.data;
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
            },
            getGnvqCatList: function () {
                this.apis.get('gnvq/category/getList?orgId=' + this.appCookies.getCookieTOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.gnvqCatList = res.data
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getGnvqList: function (gnvqCatId) {
                this.apis.get('gnvq/info/getList?categoryId=' + gnvqCatId + '&orgId=' + this.appCookies.getCookieTOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.gnvqList = res.data;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });

            },
            getLevelList: function (gnvqId) {
                this.apis.get('gnvq/level/getList?gnvqId=' + gnvqId + '&orgId=' + this.appCookies.getCookieTOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.levelList = res.data;
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            setData: function (levelId) {
                let levelInfo = {};
                for (let item of this.levelList) {
                    if (item.levelId === levelId) {
                        levelInfo = item;
                        break;
                    }
                }
                let gnvqName = ''
                for (let item of this.gnvqList) {
                    if (item.gnvqId === this.n_gnvqId) {
                        gnvqName = item.gnvqName
                        break;
                    }
                }
                this.$emit('callback', levelInfo);
                this.$emit('callbackName', gnvqName, levelInfo.levelName);
            },
            getGnvqName: function () {
                let gnvqName = ''
                for (let item of this.gnvqList) {
                    if (item.gnvqId === this.n_gnvqId) {
                        gnvqName = item.gnvqName
                        break;
                    }
                }
                return gnvqName;
            },
            getLevelName: function () {
                let levelName = ''
                for (let item of this.levelList) {
                    if (item.levelId === this.n_levelId) {
                        levelName = item.levelName;
                        break;
                    }
                }
                return levelName;
            },
            handleChangeFlag(type) {
                // select回调，判断当前下拉框是否展示
                console.log('handleChangeFlag=' + type)
                this.changeFlag = type;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .mainselect {
        height: 100%;
        width: 100%;
    }
</style>

