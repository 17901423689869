<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <div class="select-box">
                    <div class="row-box">
                        <div class="lable">发布机构：</div>
                        <div class="value">
                            <el-select filterable v-model="queryParams.orgId" @change="orgChange"
                                       placeholder="请选择培训机构">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName"
                                           :value="item.orgId"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="row-box" v-if="gnvqCatList.length>0">
                        <div class="lable">工种类别：</div>
                        <div class="value">
                            <div class="query-items">
                              <span
                                  class="item"
                                  :class="[queryParams.gnvqCatId === '' ? 'active-item' : '']"
                                  @click="getGnvqList('')">全部</span>
                                <span
                                    v-for="item in gnvqCatList" :key="item.categoryId"
                                    class="item"
                                    :class="[queryParams.gnvqCatId === item.categoryId ? 'active-item' : '']"
                                    @click="getGnvqList(item.categoryId)"
                                >{{ item.categoryName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row-box" v-if="gnvqList.length>0">
                        <div class="lable">所属工种：</div>
                        <div class="value">
                            <div class="query-items">
                              <span
                                  class="item"
                                  :class="[queryParams.gnvqId === '' ? 'active-item' : '']"
                                  @click="getLevelList('')">全部</span>
                                <span
                                    v-for="item in gnvqList" :key="item.gnvqId"
                                    class="item"
                                    :class="[queryParams.gnvqId === item.gnvqId ? 'active-item' : '']"
                                    @click="getLevelList(item.gnvqId)"
                                >{{ item.gnvqName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row-box"  v-if="levelList.length>0">
                        <div class="lable">工种等级：</div>
                        <div class="value">
                            <div class="query-items">
                              <span
                                  class="item"
                                  :class="[queryParams.levelId === '' ? 'active-item' : '']"
                                  @click="changeLevel('')">全部</span>
                                <span
                                    v-for="item in levelList" :key="item.levelId"
                                    class="item"
                                    :class="[queryParams.levelId === item.levelId ? 'active-item' : '']"
                                    @click="changeLevel(item.levelId)"
                                >{{ item.levelName }}</span>
                            </div>

                        </div>
                    </div>
                    <div class="row-box">
                        <div class="lable">关键字查询：</div>
                        <div class="value">
                            <el-input v-model="queryParams.keyword" size='small' placeholder="请输入关键字" clearable
                                      class="input-with-select">
                                <div slot="append">
                                    <el-button @click="refreshTable" icon="el-icon-search">查询</el-button>
                                </div>
                            </el-input>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div style="height: 8px">
        </div>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <div class="course-list">
                    <div class="course-list-item" v-for="(item, index) in courseList" :key="index">
                        <a @click="openCourse(item.courseId)">
                            <img :src="item.courseImgUrl" alt="">
                        </a>
                        <div class="course-msg">
                            <div class="course-msg-title">
                                <a @click="openCourse(item.courseId)">
                                    {{ item.courseName }}
                                </a>
                            </div>
                            <div v-if="item.categoryName" class="course-msg-category">
                                {{ item.categoryName }}-{{ item.gnvqName }}
                            </div>
                            <div v-else class="course-msg-category">{{ item.gnvqName }}</div>
                            <div class="course-msg-middle">
                                <div class="course-price">￥
                                    <span class="sale-price">{{ item.timesAmount }}</span>
                                    <span class="org-price">{{ item.coursePrice }}</span>
                                </div>
                                <span v-if="item.orgId!==appCookies.getCookieOID()" class="course-msg-btn"
                                      @click="toBuy(item.courseId)">立即购买</span>
                            </div>
                            <div class="course-msg-bottom">
                                <div class="msg-bottom-left">
                                    <img src="../../assets/images/course/courseIcon5.png" alt="">
                                    {{ item.levelName }}
                                </div>
                                <div class="msg-bottom-right">
                                    <img src="../../assets/images/course/courseIcon6.png" alt="">
                                    {{ item.viewCount }}人次
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div style="height: 8px">
        </div>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <!-- 分页组件 -->
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>
        <CourseBuyDrawer ref="courseBuyDrawer"></CourseBuyDrawer>
        <CourseDetailDrawer ref="courseDetailDrawer" @callback="toBuy"></CourseDetailDrawer>
    </div>
</template>

<script>
import _ from 'lodash'
import Pagination from "@c/Pagination/index.vue";
import Breadcrumb from '@/components/Breadcrumb';
import CourseBuyDrawer from './components/CourseBuyDrawer'
import CourseDetailDrawer from './components/CourseDetailDrawer'

export default {
    components: {
        Pagination,
        Breadcrumb,
        CourseBuyDrawer,
        CourseDetailDrawer
    },
    data() {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '课程市场'
                }
            ],
            //列表查询数据
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                gnvqCatId: '',
                keyword: '',
                gnvqId: '',
                levelId: '',
                orgId: '',
            },
            //列表数据
            courseList: [],
            tableLoading: false,
            orgFinding: false,
            orgList: [],
            gnvqCatList: [],
            gnvqList: [],
            levelList: [],
        };
    },
    mounted() {
        this.initTable();
        this.getOrgList();
    },
    methods: {
        orgChange: function (val) {
            if (val) {
                this.getGnvqCatList()
            } else {
                this.queryParams.gnvqCatId = ''
                this.gnvqCatList = []
                this.queryParams.gnvqId = ''
                this.gnvqList = []
                this.queryParams.levelId = ''
                this.levelList = []
            }
            this.refreshTable();
        },
        getGnvqCatList: function () {
            this.queryParams.gnvqCatId = ''
            this.gnvqCatList = []
            this.queryParams.gnvqId = ''
            this.gnvqList = []
            this.queryParams.levelId = ''
            this.levelList = []
            this.apis.get('gnvq/category/getList?orgId=' + this.queryParams.orgId)
                .then((res) => {
                    if (res.code === 1) {
                        this.gnvqCatList = res.data
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getGnvqList: function (gnvqCatId) {
            this.queryParams.gnvqCatId = gnvqCatId
            this.queryParams.gnvqId = ''
            this.gnvqList = []
            this.queryParams.levelId = ''
            this.levelList = []
            this.apis.get('gnvq/info/getList?categoryId=' + gnvqCatId + '&orgId=' + this.queryParams.orgId)
                .then((res) => {
                    if (res.code === 1) {
                        this.gnvqList = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.refreshTable();
        },
        getLevelList: function (gnvqId) {
            this.queryParams.gnvqId = gnvqId
            this.queryParams.levelId = ''
            this.levelList = []
            this.apis.get('gnvq/level/getList?gnvqId=' + gnvqId + '&orgId=' + this.queryParams.orgId)
                .then((res) => {
                    if (res.code === 1) {
                        this.levelList = res.data;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.refreshTable();
        },
        changeLevel: function (levelId) {
            this.queryParams.levelId = levelId
            this.refreshTable();
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/info/queryShop', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.courseList = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        getOrgList: _.debounce(function (val) {
            this.orgFinding = true;
            setTimeout(() => {
                this.orgFinding = false;
                this.apis.get('system/org/getTenantList?keyword=' + val + '&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.orgList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            }, 200)
        }, 500),
        toBuy: function (courseId) {
            this.$refs.courseBuyDrawer.open(courseId)
        },
        openCourse: function (courseId) {
            this.$refs.courseDetailDrawer.open(courseId)
        }
    }
}
</script>

<style lang="scss" scoped>
.select-box {
    display: flex;
    flex-direction: column;

    .row-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        min-height: 40px;

        .lable {
            width: 100px;
            min-width: 100px;
            text-align: right;
            font-weight: bold;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
        }

        .value {
            .query-items {
                display: inline-block;
                .item {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #303030;
                    line-height: 32px;
                    padding-right: 18px;
                    cursor: pointer;
                }

                .active-item {
                    font-weight: bold;
                    color: #EB2418;
                }
            }
        }
    }
}

.course-list {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 20px 0px;

    .course-list-item {
        box-sizing: border-box;
        margin-bottom: 28px;
        box-shadow: 0px 5px 20px 0px rgba(192, 192, 192, 0.2);
        width: 280px;
        margin-right: 30px;

        img {
            width: 280px;
            height: 190px;
        }

        .course-msg {
            padding: 14px 16px;

            .course-msg-title {
                cursor: pointer;
                padding-bottom: 16px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                color: #333333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .course-msg-category {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #606060;
                padding-bottom: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .course-msg-middle {
                display: flex;
                justify-content: space-between;
                height: 24px;
                line-height: 24px;

                .course-price {
                    font-size: 16px;
                    font-family: Arial;
                    color: #FF9900;

                    .sale-price {
                        font-size: 24px;
                        font-family: Arial;
                        color: #FF9900;
                    }

                    .org-price {
                        font-size: 18px;
                        color: #909090;
                        padding-left: 5px;
                        padding-right: 6px;
                        text-decoration: line-through;
                    }
                }

                .course-msg-btn {
                    text-align: center;
                    color: #FBFBFB;
                    width: 80px;
                    height: 24px;
                    background: #EE4137;
                    border-radius: 12px;
                    cursor: pointer;
                }
            }

            .course-msg-bottom {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
                padding-bottom: 4px;
                box-sizing: border-box;
                font-family: Microsoft YaHei;
                color: #CCCCCC;

                .msg-bottom-left {
                    display: flex;
                    font-size: 16px;
                    line-height: 17px;

                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 4px;
                    }
                }

                .msg-bottom-right {
                    display: flex;
                    font-size: 16px;
                    line-height: 17px;

                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}
</style>
