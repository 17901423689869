<template>
    <el-drawer
        title="调查数据查询"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content input-collapse">
            <el-row :gutter="32" class="table-container">
                <el-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        fit
                        stripe
                        highlight-current-row
                        style="width: 100%;"
                        ref="selectTable"
                        :indent="20"
                    >
                        <el-table-column label="填写人" prop="userName" width="100px" align="left" fixed="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column v-for="(item, idx) in tableHeader" :key="idx"
                                         :label="item.name" min-width="100px"  :show-overflow-tooltip="true">
                            <template #default="{row}">
                                <span>{{ row[item.code] }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="填写时间" width="140px" fixed="right" align="center">
                            <template slot-scope="{row}">
                                <span>{{ row.dataDate | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                :first-zero="0"
                                @pagination="refreshTable"/>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">取消</el-button>
        </div>
    </el-drawer>
</template>

<script>
import Pagination from "@c/Pagination/index.vue";
export default {
    components: {
        Pagination
    },
    data () {
        return {
            openDrawer: false,
            tableLoading: false,
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                asqId: '',
                keyword: '',
                deleteStatus: 0,
            },
            tableData: [],
            tableHeader: [],
        };
    },
    mounted () {
    },
    methods: {
        open: function (asqId) {
            this.queryParams.asqId = asqId
            this.refreshTable()
            this.openDrawer = true;
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('asq/mst/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data.datas;
                        this.tableHeader = res.data.headers;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
    }
}
</script>

<style lang="scss" scoped>
.item-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: dotted 1px #0e9a00;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px;
    }
}
</style>
