<template>
    <el-drawer
        title="调查问卷管理"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content input-collapse">
            <el-form :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row :gutter="8" class="row-container">
                    <el-col :span="24">
                        <el-button-group>
                            <el-button type="success" round icon="el-icon-plus" @click="toAddRow">添加问题</el-button>
                        </el-button-group>
                    </el-col>
                    <el-col :span="24">
                        <div v-for="(item, idx) in fieldList" :key="idx" class="item-box">
                            <div class="row">
                                <el-form-item :label="'（' + item.orderNum + '）' + '标题'" style="flex: 1">
                                    <el-input v-model="item.fieldName" placeholder="标题"></el-input>
                                </el-form-item>
                                <el-button type="text"  icon="el-icon-delete"
                                           @click.native.prevent="deleteRow(idx, fieldList)">删除</el-button>
                            </div>
                            <div class="row">
                                <el-form-item label="类型">
                                    <el-select size="mini" v-model="item.controlType" placeholder="选择类型">
                                        <el-option key="" label="请选择" value=""></el-option>
                                        <el-option
                                            v-for="item in controlTypes"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="排序号">
                                    <el-input v-model="item.orderNum" placeholder="排序号"></el-input>
                                </el-form-item>
                                <el-form-item label="是否必填">
                                    <el-checkbox size="mini" :true-label="1" :false-label="0" v-model="item.required"></el-checkbox>
                                </el-form-item>
                            </div>
                            <div  class="row" v-if="item.controlType==='CHECKBOX' || item.controlType==='RADIO' || item.controlType==='SELECT'">
                                <el-form-item label="选项" style="flex: 1">
                                    <template>
                                        <el-row :gutter="8">
                                            <el-col :span="4" :offset="4">
                                                <span>选项值(1/2/3/4)</span>
                                            </el-col>
                                            <el-col :span="10">
                                                <span>选项标签</span>
                                            </el-col>
                                        </el-row>
                                        <el-row :gutter="8" v-for="(option, index) in item.controlOptions" :key="index" style="margin: 5px 0">
                                            <el-col :span="4" style="text-align: right">
                                                <span>选项{{index+1}}:</span>
                                            </el-col>
                                            <el-col :span="4">
                                                <el-input type="text"  v-model="option.optionValue" placeholder="选项值"></el-input>
                                            </el-col>
                                            <el-col :span="10">
                                                <el-input type="text" v-model="option.optionLable" placeholder="选项名称"></el-input>
                                            </el-col>
                                            <el-col :span="3">
                                                <el-button  @click="optionsRemove(item, index)">删除</el-button>
                                            </el-col>
                                        </el-row>
                                        <el-row :gutter="16" >
                                            <el-col :span="14" :offset="4">
                                                <el-button plain @click="optionsAdd(item)" icon="el-icon-plus" style="width:100%">添加选项</el-button>
                                            </el-col>
                                        </el-row>
                                    </template>
                                </el-form-item>
                            </div>
                            <div  class="row">
                                <el-form-item label="备注说明" style="flex: 1">
                                    <el-input type="textarea" v-model="item.remarks" placeholder="备注说明"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">取消</el-button>
            <el-button type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    name: "AsqEditDrawer",
    data () {
        return {
            openDrawer: false,
            tableLoading: false,
            //表单验证规则
            ruleValidate: {
                // 基本信息
                asqName: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ],
            },
            fieldList: [],
            controlTypes: [ // 组件类型 INPUT, CHECKBOX , RADIO, TEXTAREA
                { code: 'INPUT', name: '文本框' },
                { code: 'TEXTAREA', name: '多行文本' },
                { code: 'CHECKBOX', name: '多选框' },
                { code: 'RADIO', name: '单选框' },
                { code: 'DATE', name: '日期' },
                // { code: 'DATERANGE', name: '日期区间' },
            ],
            asqId: '',
            info: {}
        };
    },
    mounted () {
    },
    methods: {
        open: function (asqId) {
            this.asqId = asqId
            this.getList()
            this.openDrawer = true;
        },
        getList: function () {
            this.apis.get('asq/field/getList?asqId=' + this.asqId)
                .then((res) => {
                    if (res.code === 1) {
                        this.fieldList = res.data || [];
                    } else {
                        this.fieldList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toAddRow: function () {
            this.fieldList.push({
                fieldId: '',
                fieldName: '',
                controlType: 'INPUT',
                required: 0,
                remarks: '',
                orderNum: this.fieldList.length +1,
                controlOption: '',
                controlOptions: [
                    {
                        optionValue: '',
                        optionLable: '',
                    }
                ]
            })
        },
        deleteRow(index, rows) {
            this.$confirm('确定要删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                rows.splice(index, 1);
            }).catch(() => {
            });
        },
        //添加单选题选项
        optionsAdd: function (item) {
            if (item.controlOptions === undefined || item.controlOptions === null || item.controlOptions === '') {
                item.controlOptions = []
            }
            item.controlOptions.push({ optionValue: '', optionLable: '', });
        },
        //删除单选题选项
        optionsRemove: function (item, index) {
            this.$confirm('确定要删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                item.controlOptions.splice(index, 1);
            }).catch(() => {
            });
        },
        saveData: async function (formRef) {
            let url = "asq/field/save";
            this.info.asqId = this.asqId;
            this.info.fieldList = this.fieldList;
            this.info.createBy = this.appCookies.getCookieUID();
            this.info.createByname = this.appCookies.getCookieTNAME();
            this.info.modifyBy = this.appCookies.getCookieUID();
            this.info.modifyByname = this.appCookies.getCookieTNAME();
            // 保存数据
            this.apis.postJson(url, this.info)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.item-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: dotted 1px #0e9a00;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px;
    }
}
</style>
