<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>调查问卷</span>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                                </el-form-item>

                                <el-form-item label=""  v-if="this.privRange.havePriv()">
                                    <el-select filterable v-model="queryParams.orgId"  placeholder="请选择报名机构" style="width:150px">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" :value="0"></el-option>
                                        <el-option label="已删除" :value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="selectTable"
                                :indent="20"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="问卷标题" prop="asqName" min-width="150px" align="left"></el-table-column>
                                <el-table-column label="问卷描述" prop="asqDesc" min-width="150px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="所属机构" prop="orgName" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="二维码" width="150px" align="center">
                                    <template slot-scope="{row}">
                                        <el-popover placement="bottom"
                                                    width="400"
                                                    trigger="click" @show="getSignQrUrl(row.asqId, 'H5')">
                                            <div style="width: 100%; height: 300px; padding: 30px 18px; display: flex; flex-direction: column; justify-content: space-between; align-items: center">
                                                <img :src="qrUrl" alt="" style="width: 180px; height: 180px;">
                                                <p style="font-size: 13px; color: #666666">截屏二维码，发送给学员，微信识别</p>
                                            </div>
                                            <el-button type="primary" size="mini" plain round slot="reference">H5</el-button>
                                        </el-popover>
                                        <el-popover placement="bottom"
                                                    width="400"
                                                    trigger="click" @show="getSignQrUrl(row.asqId, 'MINI')">
                                            <div style="width: 100%; height: 300px; padding: 30px 18px; display: flex; flex-direction: column; justify-content: space-between; align-items: center">
                                                <img :src="qrUrl" alt="" style="width: 180px; height: 180px;">
                                                <p style="font-size: 13px; color: #666666">截屏二维码，发送给学员，微信识别</p>
                                                <p style="font-size: 13px; color: red">识别后，需登录小程序后</p>
                                            </div>
                                            <el-button type="primary" size="mini" plain round slot="reference">小程序</el-button>
                                        </el-popover>
                                    </template>
                                </el-table-column>

                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="创建时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="220" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.asqId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.asqId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.asqId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toDefine(row.asqId)">定义</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-view" @click="toData(row.asqId)">数据</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        :first-zero="0"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="问卷基本信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="问卷标题" prop="asqName">
                            <el-input v-model="info.asqName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="问卷说明" prop="asqDesc">
                            <el-input v-model="info.asqDesc"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="工种等级" prop="categoryId">
                            <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                        :levelId.sync="info.levelId"></GnvqSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </span>
        </el-dialog>
        <AsqEditDrawer ref="asqEditDrawer"></AsqEditDrawer>
        <AsqDataDrawer ref="asqDataDrawer"></AsqDataDrawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import GnvqSelect from "@v/components/GnvqSelect.vue";
    import AsqEditDrawer from './components/AsqEditDrawer'
    import AsqDataDrawer from './components/AsqDataDrawer'
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            GnvqSelect,
            AsqEditDrawer,
            AsqDataDrawer
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                mainLoading: false,
                mainLoadingText: '正在查询数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '调查问卷'
                    }
                ],
                queryParams: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: 0,
                    dataStatus: '',
                    orgId: this.appCookies.getCookieOID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                },
                tableData: [],
                tableLoading: false,
                orgList: [],
                editDialog: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    asqName: [
                        { required: true, message: '请输入标题', trigger: 'blur' }
                    ],
                },
                qrUrl: ''
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
        },
        methods: {
            getSignQrUrl: function (asqId, type) {
                if (type === 'MINI') {
                    this.qrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=ASQ&bizId=' + asqId + "&tenantId=" + this.appCookies.getTenantId();
                } else {
                    this.qrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQrWx?bizType=ASQ&bizId=' + asqId + "&tenantId=" + this.appCookies.getTenantId();
                }
            },
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('asq/info/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 1;
                this.initTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    asqName: ''
                };
                this.editDialog = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "asq/info/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDialog = false;
                                    this.initTable();
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('asq/info/getById?asqId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        asqId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('asq/info/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toDefine: function (asqId) {
                this.$refs.asqEditDrawer.open(asqId)
            },
            toData: function (asqId) {
                this.$refs.asqDataDrawer.open(asqId)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
