<template>

    <el-drawer
        title="购买课程"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        :before-close="handleDrawerClose"
        size="90%"
    >
        <div class="demo-drawer__content">
            <el-row>
                <el-col :span="24">
                    <div class="enroll"
                         v-loading="mainLoading"
                         :element-loading-text="mainLoadingText">
                        <div  style="background-color: white; width: 1200px; min-height: 700px;">
                            <!--                <Divider />-->
                            <el-steps :active="currentStep" finish-status="success" process-status="finish" simple style="margin-top: 20px">
                                <el-step title="订单信息" description=""></el-step>
                                <el-step title="订单支付" description=""></el-step>
                                <el-step title="订单完成" description=""></el-step>
                            </el-steps>
                            <el-row :gutter="32" v-show="currentStep===0" style="margin-top: 50px">
                                <el-col :span="20" :offset="2">
                                    <el-form :model="orderInfo" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                                        <div class="course-info">
                                            <div class="headname">课程订单</div>
                                            <div class="infobox">
                                                <div class="imgbox">
                                                    <img style="width: 100%; height: 100%; border-radius: 10px;" :src="courseInfo.courseImgUrl">
                                                </div>
                                                <div class="course-right">
                                                    <div class="course-title">{{courseInfo.courseName}}</div>
                                                    <div class="course-desc">{{courseInfo.gnvqName}} {{courseInfo.levelName}}</div>
                                                    <div class="course-bom-box">
                                                        <div class="t1">￥{{courseInfo.timesAmount }}元/人次</div>
                                                        <div v-if="courseInfo.salePrice!==courseInfo.coursePrice" class="t2">{{courseInfo.coursePrice}}元</div>
                                                        <div v-else class="t1">元</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="order-items">
                                            <div class="item">
                                                <div class="name">购买人次</div>
                                                <div class="value">
                                                    <el-form-item prop="courseTimes">
                                                        <el-input v-model="orderInfo.courseTimes" @change="calValue" size='small' placeholder="请输入购买人次" clearable
                                                                  class="input-with-select">
                                                            <div slot="append">
                                                                人次
                                                            </div>
                                                        </el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="name">工种等级</div>
                                                <div class="value" style="width: 800px">
                                                    <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="orderInfo.gnvqCatId" :gnvqId.sync="orderInfo.gnvqId"
                                                                :levelId.sync="orderInfo.levelId"></GnvqSelect>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="name">订单金额</div>
                                                <div class="value">{{orderInfo.priceValue}}元</div>
                                            </div>
                                            <div class="item">
                                                <div class="name">支付方式</div>
                                                <div class="value">
                                                    <el-form-item prop="payWay">
                                                        <el-radio-group size="large" v-model="orderInfo.payWay">
                                                            <el-radio :label="1">微信支付</el-radio>
                                                            <el-radio :label="2">支付宝支付</el-radio>
                                                        </el-radio-group>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notes">
                                            提示：订单提交支付后不可取消
                                        </div>
                                        <div class="order-footer">
                                            <div class="price">
                                                <div class="t2">合计：</div>
                                                <div class="t1">￥{{orderInfo.priceValue}}元</div>
                                            </div>
                                            <div class="btn" @click="toBuy('editForm')">提交订单</div>
                                        </div>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" v-show="currentStep===1" style="margin-top: 30px">
                                <el-col :span="16" :offset="4">
                                    <el-descriptions class="margin-top" title="" :column="2" border>
                                        <el-descriptions-item :span="2">
                                            <template slot="label">
                                                <i class="el-icon-bank-card"></i>
                                                订单号
                                            </template>
                                            {{orderInfo.orderCode}}
                                        </el-descriptions-item>
                                        <el-descriptions-item :span="2">
                                            <template slot="label">
                                                <i class="el-icon-bank-card"></i>
                                                课程名称
                                            </template>
                                            {{courseInfo.courseName}}
                                        </el-descriptions-item>
                                        <el-descriptions-item :span="2">
                                            <template slot="label">
                                                <i class="el-icon-bank-card"></i>
                                                订单时间
                                            </template>
                                            {{orderInfo.createTime}}
                                        </el-descriptions-item>
                                        <el-descriptions-item>
                                            <template slot="label">
                                                <i class="el-icon-takeaway-box"></i>
                                                支付方式
                                            </template>
                                            <span v-show="orderInfo.payWay===1" style="font-size: 18px; color: red">微信支付</span>
                                            <span v-show="orderInfo.payWay===2" style="font-size: 18px; color: red">支付宝支付</span>
                                        </el-descriptions-item>
                                        <el-descriptions-item>
                                            <template slot="label">
                                                <i class="el-icon-money"></i>
                                                支付金额
                                            </template>
                                            <span style="font-size: 18px; color: red">{{orderInfo.priceValue}}</span>元
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </el-col>
                                <el-col :span="8" :offset="8" style="display: flex; justify-content: center">
                                    <div class="w_scan">
                                        <div class="w_pic">
                                            <div class="w_img">
                                                <img :src="qrCodeImg"/>
                                            </div>
                                            <div class="w_text">
                                                <p>
                                                    请使用
                                                    <span v-show="orderInfo.payWay===1">微信</span>
                                                    <span v-show="orderInfo.payWay===2">支付宝</span>
                                                    扫一扫<br>扫描二维码支付
                                                </p>
                                            </div>
                                        </div>
                                        <p style="text-align: center; margin-top: 10px; ">请在5分钟内完成扫码支付</p>
                                    </div>
                                </el-col>
                                <el-col :span="24" style="margin-bottom: 20px">
                                    <div style="display: flex; justify-content: center">
                                        <el-button style="margin-right: 5px; width: 200px" round type="primary" size="large" long @click="goLastStep">上一步</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" v-show="currentStep===2" style="margin-top: 30px">
                                <el-col :span="16" :offset="4">
                                    <el-alert type="success" show-icon :closable="false">
                        <span slot="title" style="font-size: 16px; line-height: 2">
                            您的课程订单已购买成功!
                        </span>
                                        <span style="font-size: 14px; line-height: 2">
                            可到【课程市场-我的订单】查询查看订单信息<br>
                            可到【知识库-课程管理】查询查看课程信息<br>
                        </span>
                                    </el-alert>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="editDrawer = false">关 闭</el-button>
        </div>
    </el-drawer>
</template>

<script>
import GnvqSelect from "@v/components/GnvqSelect.vue";

export default {
    components: {
        GnvqSelect
    },
    filters: {
    },
    data() {
        return {
            editDrawer: false,
            mainLoading: false,
            mainLoadingText: '请稍等，正在处理',
            courseId: '',
            courseInfo: {},
            orderInfo: {
                payWay: 1,
                courseTimes: 10,
                priceValue: 0
            },
            paying: false,
            currentStep: 0,
            qrCodeImg: '',
            setIn: null,
            ruleValidate: {
                courseTimes: [
                    { required: true, message: '请输入购买人次', trigger: 'blur' }
                ],
                gnvqCatId: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
                gnvqId: [
                    { required: true, message: '请选择工种', trigger: 'change' }
                ],
                levelId: [
                    { required: true, message: '请选择等级', trigger: 'change' }
                ],
                payWay: [
                    { required: true, message: '请选择支付方式', trigger: 'change' }
                ],
            },
        }
    },
    methods: {
        handleDrawerClose: function () {
            clearInterval(this.setIn);
            this.$emit("callback")
            this.editDrawer = false;
        },
        open: function (courseId) {
            this.courseId = courseId;
            this.editDrawer = true;
            this.getCourseInfo()
        },
        getCourseInfo: function () {
            let params = {
                courseId: this.courseId,
                orgId: this.appCookies.getCookieOID()
            }
            this.apis.postJson('org/order/course/toCreate', params, false, true)
                .then((res) => {
                    if (res.code === 1) {
                        this.courseInfo = res.data.courseInfo || {}
                        this.orderInfo = res.data;
                        this.orderInfo.courseInfo = {};
                        this.orderInfo.priceValue = this.orderInfo.courseTimes * this.courseInfo.timesAmount;

                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        calValue: function () {
            let reg = /^-?\d{1,10}?$/;//小数点左边最高16位，小数点右边最多6位
            if (reg.test(this.orderInfo.courseTimes)) {
                if (parseInt(this.orderInfo.courseTimes, 0)<=0) {
                    this.$message({ message: "输入的数值必须大于0", showClose: true, offset: 50, type: 'error' });
                    this.orderInfo.courseTimes = ''
                    return
                }
            } else {
                this.$message({ message: "请输入整数", showClose: true, offset: 50, type: 'error' });
                this.orderInfo.courseTimes = ''
                return
            }
            this.orderInfo.priceValue = this.orderInfo.courseTimes * this.courseInfo.timesAmount;
        },
        toBuy: function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.mainLoading = true
                    let that = this;
                    let reg = /^-?\d{1,10}?$/;//小数点左边最高16位，小数点右边最多6位
                    if (reg.test(this.orderInfo.courseTimes)) {
                        if (parseInt(this.orderInfo.courseTimes, 0)<=0) {
                            this.$message({ message: "输入的数值必须大于0", showClose: true, offset: 50, type: 'error' });
                            this.mainLoading = false
                            return
                        }
                    } else {
                        this.$message({ message: "请输入整数", showClose: true, offset: 50, type: 'error' });
                        this.mainLoading = false
                        return
                    }
                    // 创建订单
                    let order = {
                        orgId: this.appCookies.getCookieOID(),
                        createBy: this.appCookies.getCookieUID(),
                        createByname: this.appCookies.getCookieUNAME(),
                        courseId: that.courseId,
                        timesAmount: that.courseInfo.timesAmount,
                        courseTimes: that.orderInfo.courseTimes,
                        priceValue: that.orderInfo.priceValue,
                        payWay: that.orderInfo.payWay,
                        gnvqCatId: that.orderInfo.gnvqCatId,
                        gnvqId: that.orderInfo.gnvqId,
                        levelId: that.orderInfo.levelId
                    }
                    this.apis.postJson('org/order/course/create', order, false, true)
                        .then((res) => {
                            if (res.code === 1) {
                                // 立即支付
                                let result = res.data;
                                this.orderInfo = res.data;
                                if (result.payStatus === 1 && result.priceValue === 0) {
                                    that.finished(result.orderId, result.payStatus)
                                    // this.toSubsMsg(true, '')
                                } else {
                                    this.createPayOrder(result.orderId, result.priceValue)
                                }
                            } else {
                                this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                                this.mainLoading = false
                            }
                        }).catch((err) => {
                        this.mainLoading = false
                        console.log(err)
                    });
                }
            })
        },
        finished: function (orderId, payStatus) {
            // 检查订单
            this.mainLoading = false
            this.currentStep = 2
        },
        checkPayStatus() {
            // 循环调用后台接口
            let _this = this;
            let num = 0;
            _this.setIn = setInterval(() => {
                let data = {
                    orderId: this.orderInfo.orderId
                };
                _this.apis.postForm('org/order/course/checkPayStatus', data, false, true)
                    .then((res) => {
                        console.log(res.data)
                        if (res.data === 1) {
                            this.$message({
                                message: '支付成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            // 成功之后进入下一步
                            clearInterval(_this.setIn);
                            this.currentStep = this.currentStep + 1;
                        }
                    }).catch(err => {
                    console.log(err);
                });
                num++;
                if (num >= 100) {
                    clearInterval(_this.setIn);
                    this.$confirm('支付超时,是否重新支付?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.createPayOrder();
                    }).catch(() => {
                        // this.$router.push({ path: '/main/personal/courseorder' })
                    });
                }
            }, 3000);
        },
        createPayOrder: function () {
            // this.currentStep = this.currentStep + 1;
            // let url = "third/pay/qrPay";
            let url = "pay/org/order/createAliPayQrOrder";
            if (this.orderInfo.payWay === 1) {
                // url = "wx/pay/createQr";
                url = "pay/org/order/createWxPayQrOrder";
            }
            let date = {
                payWay: this.orderInfo.payWay,
                orderId: this.orderInfo.orderId,
                priceValue: this.orderInfo.priceValue,
                orderType: 'BUY',
                orgId: this.appCookies.getCookieOID(),
                createBy: this.appCookies.getCookieUID(),
                createByname: this.appCookies.getCookieUNAME(),
            }
            // 生成二维码
            this.apis.postForm(url, date, false, true)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        let codeUrl = res.data;
                        if (this.orderInfo.payWay === 1) {
                            codeUrl = res.data.codeUrl;
                        }
                        this.currentStep = 1;
                        this.getQrImg(codeUrl)
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        getQrImg(codeUrl) {
            // this.qrCodeImg = process.env.VUE_APP_API_HOST + 'third/pay/qrCodeImg?codeUrl=' + codeUrl;
            // const token =  this.appCookies.getCookieAccessToken();
            this.apis.axios().get('pay/org/order/genQrCodeImg?codeUrl=' + codeUrl, { 'responseType': 'arraybuffer' }, false, true)
                .then((res) => {
                    console.log(res);
                    this.qrCodeImg = 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    this.checkPayStatus();
                }).catch(err => {
                console.log(err);
            });
        },
        goLastStep: function () {
            clearInterval(this.setIn);
            this.currentStep = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.enroll {
    height: 100%;
    width: 100%;
    min-height: 700px;
    background-color: white;
    padding: 15px 10px;
    //margin-top: 150px;
    // background: url("../../assets/images/main/bg.png") repeat;
    display: flex;
    justify-content: center;
    .levelDesc {
        font-size: 16px !important;
        line-height: 2  !important;
        p {
            font-size: 16px !important;
            line-height: 2  !important;
        }
    }
}
.taglist{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    /*height: 33px;*/
    p{
        font-size: 16px;
        line-height: 33px;
        border: #cccccc 1px solid;
        border-radius: 16px;
        padding: 0 15px;
        font-weight: 400;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        min-width: 94px;
        text-align: center;
    }
    .selectP{
        background-color: #2d8cf0;
        color: white;
    }
}
.conn {
    font-size: 16px;
    a {
        color: #2d8cf0 !important;
        background: 0 0;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
        transition: color .2s ease;
    }
}
.form-title {
    margin: 20px 10px;
    padding: 10px 0px;
    box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
    h3 {
        border-left: 3px solid #0098cd;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
    }
}
.pheight {
    line-height: 34px
}
.h2 {
    border-left: 3px solid #0098cd;
    overflow: hidden;
    text-indent: 6px;
    font-size: 18px;
    color: #6f6f6f;
}
.w_scan {
    margin-top:6px;
    width: 470px;
    height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .w_pic {
        /*width: 322px;*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .w_img {
            /*width: 262px;*/
            /*height: 262px;*/
            padding: 28px;
            border: 1px solid #cecece;
            text-align: center;
            img {
                width: 256px;
                height: 256px;
            }
        }
        .w_text {
            padding-left: 136px;
            height: 68px;
            overflow: hidden;
            background: url("../../../assets/images/common/phone_q.jpg") 70px center no-repeat #e5582a;
            margin-top: 6px;
            p {
                line-height: 22px;
                font-size: 14px;
                color: #FFF;
                padding-top: 12px;
            }
        }
    }
}

.course-info {
    margin: 10px 0;
    background: #FFFFFF;
    box-sizing: border-box;
    .headname {
        font-size: 24px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 2;
    }
    .headdesc {
        font-size: 28px;
        font-weight: 400;
        color: #999999;
        line-height: 2;
    }
    .infobox {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        margin-top: 32px;
    }
    .imgbox {
        width: 220px;
        height: 164px;
    }
    .course-right {
        flex: 1;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .course-title {
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .course-desc {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 14px;
    }

    .course-bom-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
    }
    .course-bom-box .t1 {
        font-size: 24px;
        font-weight: 500;
        color: #f37b1d;
    }
    .course-bom-box .t2 {
        font-size: 20px;
        font-weight: 400;
        text-decoration: line-through;
        color: #999999;
        padding-left: 3px;
    }
}
.order-items {
    padding: 20px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-top: 6px solid #EDEDED;
    .item{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .item .name{
        font-size: 20px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 2;
        min-width: 150px;
    }
    .item .value{
        font-size: 20px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        line-height: 2;
    }
}
.notes {
    border-top: 6px solid #EDEDED;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    color: #B2B2B2;
    line-height: 30px;
}

.order-footer {
    box-sizing: border-box;
    padding: 10px 20px;
    /* background:rgba(247,247,247,1); */
    background: #fff;
    height: 98px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 150px;
    .price {
        flex: 1;
        display: flex;
        justify-items: center;
        align-items: center;
        text-align: right;
        margin-right: 20px;
        .t2 {
            font-size: 30px;
            color: #666666;
        }
        .t1 {
            font-size: 34px;
            font-weight: 500;
            color: #f37b1d;
        }
    }
    .btn {
        width: 200px;
        height: 44px;
        background: #fbbd08;
        border-radius: 40px;
        line-height: 44px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }
}
</style>
